.contato-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #143C5B 0%, #2a5298 100%);
  color: white;
  text-align: center;
  padding: 20px;
}

.contato-content {
  max-width: 1000px;
}

.contato-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contato-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
}