.home-page-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #143C5B 0%, #2a5298 100%);
  color: white;
  text-align: center;
  padding: 20px;
}

.content {
  max-width: 600px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.message-home-page {
  font-size: 1.5rem;
  margin-bottom: 40px;
}