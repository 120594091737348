.header-menu {
  background-color: white;
  padding: 10px 20px;
  border-top: #143C5B solid 5px;
}

.header-container {
  /* padding: 20px 40px; */
  display: flex;
  justify-content: space-between;
}

.header-image {
  width: 120px;
  height: auto;
}

.header-menu ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

.header-menu li {
  display: inline;
}

.header-menu a {
  color: #143C5B;
  text-decoration: none;
}

.header-menu a:hover {
  text-decoration: underline;
}

.hamburger {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

.nav-menu {
  display: flex;
}

.nav-menu.open {
  display: block;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    background-color: white;
    text-align: center;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .nav-menu ul li a {
    font-size: 1.5rem;
  }
}