.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.social-icons {
  margin-bottom: 10px;
  width: 50%;
  align-content: center;
  text-align: left;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #ccc;
}

.company-address {
  font-size: 14px;
  line-height: 1.5;
  width: 50%;
  text-align: right;
}